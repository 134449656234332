import { PRODUCT_IMAGE_BASE_FRAGMENT } from '@/graphql/_Fragments/ProductImage/Base';
import { EXHIBITOR_BASE_FRAGMENT } from '@/graphql/_Fragments/Exhibitor/Base';

export const PRODUCT_CARD_FRAGMENT = `
  fragment productCardFragment on Product {
    id
    uid
    schemaCode
    name
    featured
    images {
      ...productImageBaseFragment
    }
    exhibitor {
      ...exhibitorBaseFragment
    }
    categoriesInContext(context: "product_card") {
      uid
      name
    }
    _isBookmarked(myUid: "%authUser%")
    _isRecommendedForMe
    _isExhibitorFeatured
  }
  ${PRODUCT_IMAGE_BASE_FRAGMENT}
  ${EXHIBITOR_BASE_FRAGMENT}
`;
